import { ENVIRONMENT_TOKEN, Environment } from '@actassa/api';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PlacementInterface } from '../../../interfaces/placement.interface';

@Injectable()
export class PlacementService {
    constructor(
        @Inject(ENVIRONMENT_TOKEN) protected readonly environment: Environment,
        private readonly http: HttpClient,
    ) { }

    public getCandidatePlacement$(placementId: number): Observable<PlacementInterface> {
        const url = `${this.environment.apiURL}/v1/placement/${placementId}`;

        return this.http.get<PlacementInterface>(url);
    }
}
