import { Injectable } from '@angular/core';

import { PinData } from '../timesheet-hours/components/pin/pin.interface';

@Injectable({
    providedIn: 'root',
})
export class PinStoreService {
    private store = new Map<string, PinData>();

    constructor() { }

    public setValue(timesheetId: string, value: PinData): void {
        this.store.set(timesheetId, value);
    }

    public getValue(timesheetId: string): PinData {
        return this.store.get(timesheetId);
    }
}
