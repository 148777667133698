import { InformErrorService, MainMenuSharedModule } from '@actassa/shared';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgxsModule } from '@ngxs/store';

import { JobsPlacementsState } from './+state/app-state/app.state';
import { MenuItemsComponent } from './components/menu-items/menu-items.component';
import { JobsPlacementsRoutingModule } from './jobs-placements-routing.module';
import { JobsPlacementsComponent } from './jobs-placements.component';
import { TimesheetApproveV2Service } from './pages/timesheet-approve-v2/timesheet-approve-v2.service';
import { CollectionsService } from './services/collections.service';
import { EmptyTextService } from './services/empty-text-service.service';
import { JobChangeDictionaryService } from './services/job-change-dictionary.service';
import { JobsService } from './services/jobs.service';
import { MenuItemsService } from './services/menu-items.service';
import { PlacementsService } from './services/placements.service';
import { PushHandlerService } from './services/push-handler.service';
import { SavedFiltersApiService } from './services/saved-filters.api.service';
import { SavedFiltersEffectsService } from './services/saved-filters.effects.service';
import { SavedFiltersModalService } from './services/saved-filters.modal.service';
import { SchedulerService } from './services/scheduler.service';
import { ShiftsService } from './services/shifts.service';
import { TimesheetFacadeService } from './services/time-sheet-facade.service';
import { TimesheetService } from './services/time-sheet.service';
import {
    PlacementService,
    TimesheetApprovesService,
    TimesheetExpensesService,
    TimesheetHourBreaksService,
    TimesheetHoursService,
    TimesheetsFacadeService,
    TimesheetsService,
    TimesheetsStoreService,
} from './pages/v3/services';
import { PinStoreService } from './pages/v3/services/pin-store.service';

@NgModule({
    declarations: [
        JobsPlacementsComponent,
        MenuItemsComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        JobsPlacementsRoutingModule,
        MainMenuSharedModule,
        NgxsModule.forFeature([JobsPlacementsState]),
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        Diagnostic,
        EmptyTextService,
        JobChangeDictionaryService,
        MenuItemsService,
        SavedFiltersEffectsService,
        SavedFiltersApiService,
        SavedFiltersModalService,
        // TimeSheetService,
        InformErrorService,

        TimesheetsService,
        TimesheetExpensesService,
        TimesheetHoursService,
        TimesheetApprovesService,
        TimesheetHourBreaksService,
        TimesheetsStoreService,
        TimesheetsFacadeService,
        PinStoreService,
        PlacementService,
        // TimeSheetFacadeService,
        // TimesheetApproveV2Service,
    ],
    exports: [
        MenuItemsComponent,
    ],
})
export class JobsPlacementsModule {
    // NOTE: preload services
    constructor(
        private readonly collectionsService: CollectionsService,
        private readonly jobsService: JobsService,
        private readonly placementsService: PlacementsService,
        private readonly pushHandlerService: PushHandlerService,
        private readonly schedulerService: SchedulerService,
        private readonly shiftsService: ShiftsService,
        private readonly timesheetsStoreService: TimesheetsStoreService,
    ) { }

    public static forRoot(): ModuleWithProviders<JobsPlacementsModule> {
        return {
            ngModule: JobsPlacementsModule,
            providers: [
                CollectionsService,
                JobsService,
                MenuItemsService,
                PlacementsService,
                PushHandlerService,
                SchedulerService,
                ShiftsService,
                // TimeSheetFacadeService,
                TimesheetService,
                TimesheetFacadeService,
                TimesheetApproveV2Service,
            ],
        };
    }
}
