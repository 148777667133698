import { ENVIRONMENT_TOKEN, Environment } from '@actassa/api';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { isEmpty } from 'lodash-es';
import { Observable, finalize, of, tap } from 'rxjs';
import { format } from 'date-fns';

import { AbstractDataLoader } from '../../../abstracts/data-loader.service';
import { PlacementInterface } from '../../../interfaces/placement.interface';

import { TimesheetV3, TimesheetV3Complex } from '../interfaces/timesheet-v3.interface';

@Injectable()
export class TimesheetsService extends AbstractDataLoader {
    constructor(
        @Inject(ENVIRONMENT_TOKEN) protected readonly environment: Environment,
        private readonly http: HttpClient,
    ) {
        super(environment);
    }

    public load$(data: { placementIds: Array<number>; week: Date; }): Observable<Array<TimesheetV3Complex>> {
        const { placementIds, week } = data || {};

        if (isEmpty(placementIds)) {
            return of([]);
        }

        this.startLoading();

        const params = new HttpParams({ fromObject: { placementIds, week: format(week, 'yyyy-MM-dd') } });
        const url = this.buildUrl('v3/timesheets');

        return this.http.get<Array<TimesheetV3Complex>>(url, { params })
            .pipe(
                tap(() => this.stopLoading()),
                finalize(() => this.stopLoading()),
            );
    }

    public save$(dto: TimesheetV3): Observable<unknown> {
        const url = `${this.environment.apiURL}/v3/timesheet`;

        return this.http.post(url, dto);
    }

    public getCandidatePlacement$(placementId: number): Observable<PlacementInterface> {
        const url = `${this.environment.apiURL}/v1/placement/${placementId}`;

        return this.http.get<PlacementInterface>(url);
    }
}
