import { ConsultantSectionsEnum } from '../enums/consultant-sections.enum';

export const ConsultantRoutesDictionary: Record<ConsultantSectionsEnum, string> = {
    [ConsultantSectionsEnum.DATA_COLLECTION]: 'data-collection',
    [ConsultantSectionsEnum.DRAFTS]: 'agent',
    [ConsultantSectionsEnum.HELP]: 'help',
    [ConsultantSectionsEnum.HOME]: 'home',
    [ConsultantSectionsEnum.JOBS_PLACEMENTS]: 'jobs-placements',
    [ConsultantSectionsEnum.JOBS]: 'jobs',
    [ConsultantSectionsEnum.LOGOUT]: '/logout',
    [ConsultantSectionsEnum.MAIN_INDEX_SEARCH]: 'main-index',
    [ConsultantSectionsEnum.MESSAGING]: 'messaging',
    [ConsultantSectionsEnum.SELECT_APPLICATION]: '/select-app',
    [ConsultantSectionsEnum.SETTINGS]: 'settings',
};
