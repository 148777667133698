import { MainMenuItemInterface } from '@actassa/api';
import { PageOptions } from '../pages/v3/interfaces/page-options.interface';
import {
    ALLOWED_GROUP_NAMES,
    DEFAULT_LOOK_BACK_DAYS,
    EXCLUDE_PIN_CONSULTANT_GROUP_NAMES,
    EXCLUDE_POSITION_CONSULTANT_GROUP_NAMES,
    INCLUDE_PIN_CONSULTANT_GROUP_NAMES,
    INCLUDE_POSITION_CONSULTANT_GROUP_NAMES,
    MAX_WEEK_BACKWARD,
    MAX_WEEK_BACKWARD_BY_BRANCH,
    MAX_WEEK_FORWARD,
    PIN_LENGTH,
    PROHIBITED_WARD_NAMES,
    SORT_ORDER,
} from '../pages/v3/constants';

export function getTimesheetOptions(menuItem: MainMenuItemInterface): PageOptions {
    return {
        allowedGroupNames: menuItem?.options?.allowedGroupNames ?? ALLOWED_GROUP_NAMES,
        allowExpenses: Boolean(menuItem?.options?.allowExpenses),
        excludePinEntryConsultantGroupNames: menuItem?.options?.excludePinEntryConsultantGroupNames || EXCLUDE_PIN_CONSULTANT_GROUP_NAMES,
        excludePositionConsultantGroupNames: menuItem?.options?.excludePositionConsultantGroupNames || EXCLUDE_POSITION_CONSULTANT_GROUP_NAMES,
        includePinEntryConsultantGroupNames: menuItem?.options?.includePinEntryConsultantGroupNames || INCLUDE_PIN_CONSULTANT_GROUP_NAMES,
        includePositionConsultantGroupNames: menuItem?.options?.includePositionConsultantGroupNames || INCLUDE_POSITION_CONSULTANT_GROUP_NAMES,
        lookBackDays: menuItem?.options?.lookBackDays ?? DEFAULT_LOOK_BACK_DAYS,
        maxWeekBackward: menuItem?.options?.maxWeekBackward ?? MAX_WEEK_BACKWARD,
        maxWeekBackwardByBranch: menuItem?.options?.maxWeekBackwardByBranch || MAX_WEEK_BACKWARD_BY_BRANCH,
        maxWeekForward: menuItem?.options?.maxWeekForward ?? MAX_WEEK_FORWARD,
        pinLength: menuItem?.options?.pinLength || PIN_LENGTH,
        prohibitedWardNames: menuItem?.options?.prohibitedWardNames || PROHIBITED_WARD_NAMES,
        sortOrder: menuItem?.options?.sortOrder ?? SORT_ORDER,
    };
}
